$(document).ready(function() {
    var searchCat = $('.js-search-cat');

    $('.js-search-cat .main-b').on('click', function() {
        if ($(this).parent().hasClass('top')) {
            $(this).toggleClass('clo');
            $(this).parent().children('ul').toggle(200);
        }
    });

    searchCat.on('click', function() {
        if (!$(this).hasClass('dis')) {

            var windowLocation = window.location.href;

            windowLocation = windowLocation.split('&page=')[0];
            windowLocation = windowLocation.split('&category_id=')[0];


            window.location.href = (windowLocation + '&category_id=' + $(this).data('id'));
        }
    });
});