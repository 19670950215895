(function ($, undefined) {

    var $body = $('body'),
        $windowWidth = $(window).width();
    
    // $('#mobileShopCatalogMenu').modal('show');
    /**
     * Quantity Input plus/minus buttons
     */
    var $quantityDiv = $('.js-quantity');
    var $quantityButton = $('.js-quantity__button', $quantityDiv);
    var $quantityInput = $('.js-quantity__input', $quantityDiv);

    $quantityButton.click(function (e) {
        e.preventDefault();

        var $fieldName = $(this).attr('data-field'),
            $type = $(this).attr('data-type'),
            $input = $("input[name='" + $fieldName + "']", $quantityDiv),
            $currentVal = parseInt($input.val());

        if (!isNaN($currentVal)) {
            if ($type === 'minus') {
                if ($currentVal > $input.attr('min')) {
                    $input.val($currentVal - 1).change();
                }
                if (parseInt($input.val()) === parseInt($input.attr('min'))) {
                    $(this).attr('disabled', true);
                }
            } else if ($type === 'plus') {
                $input.val($currentVal + 1).change();
            }
        } else {
            $input.val(0);
        }
    });

    $quantityInput.focusin(function () {
        $(this).data('oldValue', $(this).val());
    });

    $quantityInput.change(function () {
        var $minValue = parseInt($(this).attr('min'));
        var $valueCurrent = parseInt($(this).val());

        if ($valueCurrent >= $minValue) {
            $(".js-quantity__button[data-type='minus']").removeAttr('disabled')
        } else {
            alert('Sorry, the minimum value was reached');
            $(this).val($(this).data('oldValue'));
        }
    });

    $quantityInput.keydown(function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode === 65 && e.ctrlKey === true) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });

    /**
     * Go top
     */
    var $top = $('.js-go-top'),
        $html = $('html');

    $top.on('click', function (e) {
        $html.animate({
            scrollTop: $html.offset().top
        }, 1000);

        return false;
    });

    $(window).scroll(function () {
        var scrollTop = $(window).scrollTop();

        if (scrollTop > 750) {
            $top.addClass('show');
        } else {
            $top.removeClass('show');
        }
    });

    /**
     * show bootstrap dropdown on hover (main-menu).
     * We use hover only on desktop devices
     */
    if ($windowWidth > 1199) {
        var $mainMenuLink = $(".sp-header-menu__link.sp-toggle");

        $mainMenuLink.on("mouseenter", function () {
            // make sure it is not shown:
            var $this = $(this);

            if (!$this.parent().hasClass("show")) {
                $this.click();
            }

            $this.addClass('active');
        });

        $(".sp-header-menu__item.dropdown").on("mouseleave", function () {
            // make sure it is shown:
            if ($(this).hasClass("show")) {
                $(this).children('.sp-toggle').removeClass('active').click();
            }
        });
    }

    /**
     * JS hover
     */
    var $hoverBlock = $('.js-hover');

    if ($windowWidth > 1199) {
        $($hoverBlock).hover(function () {
            var $this = $(this);

            $this.find('.js-hover__dropdown').stop(true, true).delay(100).fadeIn(300);
            $this.find('.sp-toggle').addClass('active');
        }, function () {
            var $this = $(this);

            $this.find('.js-hover__dropdown').stop(true, true).delay(100).fadeOut(300);
            $this.find('.sp-toggle').removeClass('active');
        });
    } else {
        // var $toggles = $('.sp-toggle'),
        //     $dropdowns = $('.js-hover__dropdown');
        //
        // $($hoverBlock).on('click', function() {
        //     var $this = $(this),
        //         $toggleThis = $this.find('.sp-toggle'),
        //         $dropdownThis = $this.find('.js-hover__dropdown');
        //
        //     $toggles.removeClass('active');
        //
        //     var i = 0;
        //     $dropdowns.each(function() {
        //         i++;
        //
        //         $(this).stop(true, true).delay(100).removeClass('active-dropdown').fadeOut(300, function() {
        //             if (i === $dropdowns.length) {
        //                 closeDropdownTablet($toggleThis, $dropdownThis);
        //             }
        //         });
        //     });
        //
        // });
    }

    /**
     * JS Header hover
     */
    $(function () {
        var $catalogMenuLink = $('.sp-header-menu-category__block'),
            $catalogMenuDropdown = $('.sp-header-menu-category__dropdown'),
            $catalogMenuBlock = $('.js-header-hover'),
            $homePage = $('body.homepage').length;

        if ($homePage) {
            if ($windowWidth > 1199) {
                $catalogMenuLink.addClass('active');
                $catalogMenuDropdown.addClass('activated');

                $catalogMenuLink.on('click', function () {
                    if ($catalogMenuLink.hasClass('active')) {
                        $catalogMenuDropdown.removeClass('activated').delay(100).fadeOut(300);
                        $catalogMenuLink.removeClass('active');
                    } else {
                        $catalogMenuDropdown.addClass('activated').delay(100).fadeIn(300);
                        $catalogMenuLink.addClass('active');
                    }
                });

                return false;
            }
        }

        // other pages
        if ($windowWidth > 1199) {
            $($catalogMenuBlock).hover(function () {
                var $this = $(this);

                $this.find('.js-hover__dropdown').stop(true, true).delay(100).fadeIn(300);
                $catalogMenuLink.addClass('active');
            }, function () {
                var $this = $(this);

                $this.find('.js-hover__dropdown').stop(true, true).delay(100).fadeOut(300);
                $catalogMenuLink.removeClass('active');
            });
        }
    });

    /**
     * Menu category submenu
     */
    $(function () {
        var $menuBlock = $('.sp-header-menu-category__list'),
            $menuItem = $('.sp-header-menu-category__link', $menuBlock),
            $submenuBlock = $('.sp-header-menu-category-submenu'),
            $headerCatalogDropdown = $('.js-header-hover .js-hover__dropdown'),
            $banner = $('.sp-header-menu-category__banner'),
            $submenuRow = $('.sp-header-menu-category-submenu__row'),
            $categoryMenuLink = $('.sp-header-menu-category__block'),
            $headerPhoneIcon = $('.sp-header-call__icon'),
            $headerPhoneBlock = $('.sp-header-call__phone'),
            $blackWrap = $('.black-wrap');

        $menuItem.on('mouseenter', function () {
            $banner.show();
            $blackWrap.fadeIn();
            $headerPhoneIcon.addClass('disable-z-index');
            $headerPhoneBlock.addClass('disable-z-index');

            var $this = $(this),
                $activeSubmenuRow = $('.sp-header-menu-category-submenu__row[data-submenu=' + $this.data('submenu-index') + ']');

            $submenuRow.removeClass('active');
            $submenuBlock.hide();

            if ($activeSubmenuRow.length) {
                $submenuBlock.show();
                $activeSubmenuRow.addClass('active');
            }
        });

        $headerCatalogDropdown.on('mouseleave', function () {
            $submenuBlock.fadeOut(300);
            $banner.hide();
            $blackWrap.fadeOut(function () {
                $headerPhoneIcon.removeClass('disable-z-index');
                $headerPhoneBlock.removeClass('disable-z-index');
            });
        });

        $categoryMenuLink.on('click', function (e) {
            e.preventDefault();
        });
    });

    /**
     * Mobile menu
     */
    $(function () {
        var $mobileMenuToggleBtn = $('.drop-toggle'),
            $allMobileDropdownMenus = $('.drop-menu'),
            $allMobileSubDropdownMenus = $('.drop-submenu'),
            $mobileSubMenuToggleBtn = $('.drop-submenu-toggle');

        $mobileMenuToggleBtn.on('click', function (e) {
            var $this = $(this),
                $currentMenu = $this.next('.drop-menu');

            $allMobileDropdownMenus.not($currentMenu).removeClass('d-block');
            $allMobileSubDropdownMenus.removeClass('d-block');
            $currentMenu.toggleClass('d-block');
        });

        $mobileSubMenuToggleBtn.on('click', function (e) {
            var $this = $(this),
                $currentSubMenu = $this.next('.drop-submenu');

            $allMobileSubDropdownMenus.not($currentSubMenu).removeClass('d-block');
            $currentSubMenu.toggleClass('d-block');
        });
    });

    /**
     * Smooth scroll
     */
    $(function () {
        var $smoothScroll = $(".js-smooth-scroll");

        $smoothScroll.on("click", function (e) {
            e.preventDefault();
            var id = $(this).attr('href'),
                top = $(id).offset().top;
            $('body,html').animate({scrollTop: top - 20}, 500);
        });
    });

    /**
     * Cookies
     */
    $(function () {
        var cookiesApplyBtn = $('.js-cookies-apply'),
            cookiesCancelBtn = $('.js-cookies-cancel'),
            cookiesBlock = $('.js-cookies-block');

        if (!localStorage.getItem('isAgreement')) {
            cookiesBlock.addClass('active');
        }

        cookiesApplyBtn.on('click', function(e) {
            e.preventDefault();
            cookiesBlock.removeClass('active');
            localStorage.setItem('isAgreement', true);
        });

        cookiesCancelBtn.on('click', function(e) {
            e.preventDefault();
            cookiesBlock.removeClass('active');
        });
    });

    /**
     * Show hide juridical block on registration page
     */
    $(function () {
        var $showJuridicalBlockBtn = $('.js-juridical-toggle'),
            $juridicalBlock = $('#js-juridical-block');

        $showJuridicalBlockBtn.on('click', function (e) {
            e.preventDefault();
            var $this = $(this);

            $showJuridicalBlockBtn.removeClass('active');
            $this.addClass('active');
            $juridicalBlock.toggle();
        });
    });

    /**
     * ajax search (make screen darken)
     */
    $(function () {
        var $searchInput = $('#text-search'),
            $searchDiv = $('.sp-header__search'),
            $categoryDropdown = $('.sp-header-menu-category__dropdown'),
            $blackWrap = $('.black-wrap'),
            $headerPhoneIcon = $('.sp-header-call__icon'),
            $headerPhoneBlock = $('.sp-header-call__phone');

        if ($windowWidth > 992) {
            $searchInput.focusin( function () {
                $blackWrap.fadeIn();
                $searchDiv.addClass('active');
                $headerPhoneIcon.addClass('disable-z-index');
                $headerPhoneBlock.addClass('disable-z-index');
                $categoryDropdown.addClass('disable-z-index');
            });

            $searchInput.focusout( function () {
                $('.sp-header-search-result').fadeOut(); // dont wrap selector in var, or it wouldn't fadeOut()
                $blackWrap.fadeOut(300, function() {
                    $searchDiv.removeClass('active');
                    $headerPhoneIcon.removeClass('disable-z-index');
                    $headerPhoneBlock.removeClass('disable-z-index');
                    $categoryDropdown.removeClass('disable-z-index');
                });
            });
        }
    });

    /**
     * Mobile sidebar filter
     */
    $(function () {
        var $showSidebarBtn = $('.js-show-mobile-filter'),
            $sidebar =  $('.sp-sidebar'),
            $blackWrap = $('.black-wrap');

        $showSidebarBtn.on('click', function () {
            $body.addClass('no-scroll w-100');
            $sidebar.addClass('sp-sidebar__show');
            $blackWrap.addClass('mobile-filter-bg').fadeIn();
        });

        $body.on('click', '.js-hide-mobile-filter', function () {
            $body.removeClass('no-scroll');
            $sidebar.removeClass('sp-sidebar__show');
            $blackWrap.fadeOut();
        });

        $body.on('click', '.mobile-filter-bg', function () {
            $body.removeClass('no-scroll w-100');
            $sidebar.removeClass('sp-sidebar__show');
            $blackWrap.removeClass('mobile-filter-bg').fadeOut();
        });
    });

    /**
     * Checkout page: switch radio buttons on tab change
     */
    $(function () {
        $body.on('click', '#collapse-shipping-method .sp-tabs__link', function () {
            var $this = $(this),
                $tab = $this.attr('href'),
                $radioBtn =  $('.radio input[name="shipping_method"]', $tab);

            $radioBtn.prop("checked", true);
        });
    });

    /**
     * Sticky header
     */
    // @TODO: Перед добавлением кода на production пофиксить баг при котором при раскрытие субкатегорий в меню "каталог товаров" к примеру
    // @TODO: "Сантехника" при всех открытых "+ еще 5" вместо скрола по меню оно скролится вместе с экраном и не возможно добраться
    // @TODO: до последних субкатегорий
    $(function () {
        if ($windowWidth > 992) {
            var scrollFlag = true,
                $headerCatalogMenu = $('.sp-header-menu-category__dropdown'),
                $headerCatalogMenuBlock = $('.sp-header-menu-category__block'),
                $homePage = $('body.homepage').length;

            // When the user scrolls the page, execute myFunction
            window.onscroll = function() {makeHeaderSticky()};

            // Get the header
            var header = $('.sp-header'),
                headerBar = $('.sp-header__bottom');

            // Get the offset position of the navbar
            var sticky = headerBar.offset();

            // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
            function makeHeaderSticky() {
                if ($(window).scrollTop() > sticky.top) {
                    if (scrollFlag) {
                        headerBar.addClass('sticky');
                        header.addClass('fix-sticky-padding');

                        // if home page: fade out menu(header category)
                        if ($homePage){
                            $headerCatalogMenu.removeClass('activated').delay(100).fadeOut(300);
                            $headerCatalogMenuBlock.removeClass('active');
                        }
                        scrollFlag = false;
                    }
                } else {
                    if (!scrollFlag){
                        headerBar.removeClass('sticky');
                        header.removeClass('fix-sticky-padding');
                        // if home page and menu is close: open it
                        if ($homePage && !$headerCatalogMenu.hasClass('activated')){
                            $headerCatalogMenu.addClass('activated').delay(100).fadeIn(300);
                            $headerCatalogMenuBlock.addClass('active');
                        }
                        scrollFlag = true;
                    }
                }
            }
        }
    });

})(jQuery);
