(function ($, undefined) {
    var footerTitle = $('.js-footer-btn'),
        openClass = 'js-footer-open';

    footerTitle.on('click', function () {

        if ($(document).outerWidth() >= 768) return false;

        var thisFooterTitle = $(this),
            thisSeparator = thisFooterTitle.next(),
            thisNavigation = thisSeparator.next();

        if (thisFooterTitle.hasClass(openClass)) {
            toggleFooterMenu(thisNavigation, thisSeparator, thisFooterTitle);
        } else {
            toggleFooterMenu(thisSeparator, thisNavigation, thisFooterTitle);
        }

    });

    function toggleFooterMenu(first, last, title) {
        first.slideToggle((first.actual('innerHeight') * 1), function () {
            last.slideToggle((last.actual('innerHeight') * 1), function() {
                title.toggleClass(openClass);
            });
        });
    }

})(jQuery);
