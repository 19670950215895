(function ($, undefined) {
    /**
     * Header menu list
     */
    var $burger = $('.sp-header__burger'),
        $headerBottom = $('.sp-header__bottom');

    $burger.on('click', function (e) {
        e.preventDefault();
        $headerBottom.slideToggle(300);
    })

    /**
     * Single product slider
     */

    var $singleDealerProduct = $('.js-single-dealer-product-slider');

    if ($singleDealerProduct.length) {
        new Swiper($singleDealerProduct, {
            pagination: {
                el: '.js-single-dealer-product-slider__pagination',
                clickable: true,
            },
            mousewheel: {
                invert: true,
            },
        });
    }
})(jQuery);
