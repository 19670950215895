(function ($, undefined) {
    var $main = $('.js-sp-main-slider'),
        $brand = $('.js-sp-brands-slider'),
        $sales = $('.js-sp-sale-products-slider'),
        $products = $('.js-sp-products-slider'),
        $postProducts = $('.js-sp-post-slider'),
        $relatedPosts = $('.js-sp-related-posts-slider'),
        $singleProduct = $('.js-single-product-slider'),
        $singlePost = $('.js-single-post-slider'),
        $singlePostThumbs = $('.js-single-post-slider-thumb'),
        $singleProductThumbs = $('.js-single-product-slider-thumb'),
        $smallProducts = $('.js-sp-small-products-slider'),
        $weekReleases = $('.js-sp-week-releases-slider');

    if ($brand.length) {
        new Swiper($brand, {
            loop: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            spaceBetween: 80,
            pagination: {
                el: '.js-sp-brand-slider__pagination',
                clickable: true,
            },
            breakpoints: {
                575: {
                    spaceBetween: 20,
                    slidesPerView: 3,
                },
            }
        });
    }


    if ($main.length) {
        var mainSwiper = new Swiper($main, {
            autoplay: {
                delay: 6000,
            },
            pagination: {
                el: '.js-sp-main-slider__pagination',
                clickable: true,
            },
        });

        $main.hover(function() {
            mainSwiper.autoplay.stop();
        }, function() {
            mainSwiper.autoplay.start();
        });
    }

    if ($sales.length){
        new Swiper($sales, {
            slidesPerView: 3,
            spaceBetween: 30,
            pagination: {
                el: '.js-sp-sale-products-slider__pagination',
                clickable: true,
            },
            navigation: {
                nextEl: $sales.parents('.sp-sale-products').find('.sp-title-block__button_next'),
                prevEl: $sales.parents('.sp-sale-products').find('.sp-title-block__button_prev'),
            },
            breakpoints: {
                1199: {
                    slidesPerView: 2,
                },
                575: {
                    slidesPerView: 1,
                },
            }
        });
    }

    if ($weekReleases.length) {
        new Swiper($weekReleases, {
            spaceBetween: 30,
            slidesPerView: 2,
            pagination: {
                el: '.js-sp-week-releases__pagination',
                clickable: true,
            },
            navigation: {
                nextEl: $weekReleases.parent().find('.sp-title-block__button_next'),
                prevEl: $weekReleases.parent().find('.sp-title-block__button_prev'),
            },
            breakpoints: {
                991: {
                    slidesPerView: 1,
                },
            }
        });
    }

    if ($products.length) {
        new Swiper($products, {
            spaceBetween: 30,
            slidesPerView: 4,
            pagination: {
                el: '.js-sp-products-slider__pagination',
                clickable: true,
            },
            navigation: {
                nextEl: $products.parent().find('.sp-title-block__button_next'),
                prevEl: $products.parent().find('.sp-title-block__button_prev'),
            },
            breakpoints: {
                991: {
                    slidesPerView: 2,
                },
                575: {
                    slidesPerView: 1,
                },
            }
        });
    }

    if ($postProducts.length) {
        new Swiper($postProducts, {
            spaceBetween: 30,
            slidesPerView: 3,
            pagination: {
                el: '.js-sp-post-slider__pagination',
                clickable: true,
            },
            navigation: {
                nextEl: $postProducts.parent().find('.sp-title-block__button_next'),
                prevEl: $postProducts.parent().find('.sp-title-block__button_prev'),
            },
            breakpoints: {
                991: {
                    slidesPerView: 2,
                },
                575: {
                    slidesPerView: 1,
                },
            }
        });
    }

    if ($relatedPosts.length) {
        new Swiper($relatedPosts, {
            spaceBetween: 30,
            slidesPerView: 3,
            pagination: {
                el: '.js-sp-related-posts-slider__pagination',
                clickable: true,
            },
            navigation: {
                nextEl: $relatedPosts.parent().find('.sp-title-block__button_next'),
                prevEl: $relatedPosts.parent().find('.sp-title-block__button_prev'),
            },
            breakpoints: {
                991: {
                    slidesPerView: 2,
                },
                575: {
                    slidesPerView: 1,
                },
            }
        });
    }

    if ($smallProducts.length) {
        new Swiper($smallProducts, {
            spaceBetween: 30,
            slidesPerView: 6,
            pagination: {
                el: '.js-sp-small-products-slider__pagination',
                clickable: true,
            },
            navigation: {
                nextEl: $smallProducts.parent().find('.sp-title-block__button_next'),
                prevEl: $smallProducts.parent().find('.sp-title-block__button_prev'),
            },
            breakpoints: {
                1199: {
                    slidesPerView: 4,
                },
                991: {
                    slidesPerView: 2,
                },
                575: {
                    slidesPerView: 1,
                },
            }
        });
    }

    if ($singleProduct){
        new Swiper($singleProduct, {
            thumbs: {
                swiper: {
                    el: $singleProductThumbs,
                    allowSlidePrev: false,
                    allowSlideNext: false,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                }
            }
        });
    }

    if ($singlePost.length){
        var singlePost = new Swiper($singlePost, {
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
                hide: true,
            },
            keyboard: {
                enabled: true,
            },
        });

        var singlePostThumbs = new Swiper($singlePostThumbs, {
            spaceBetween: 10,
            centeredSlides: true, // if remove this line, slider and thumb will be async
            slidesPerView: 'auto',
            touchRatio: 0.2,
            slideToClickedSlide: true,
        });

        singlePost.controller.control = singlePostThumbs;
        singlePostThumbs.controller.control = singlePost;
    }
})(jQuery);
